import React from "react";
import { Link } from "react-router-dom";

const Service_card = ({ content, linkto, style }) => {
  return (
    <section class=" text-white ">
      <div class="mx-auto max-w-screen-xl  px-4 py-4 xs:py-4 xs:px-0 sm:px-6 sm:py-12 lg:px-4 lg:py-3 h-full w-[22rem]  xs:w-[95%]">
        {/* <div class="lg:py-24 text-left">
          <h3 class="text-xl font-bold sm:text-xl text-emarld-700 ">
            ABOUT US
          </h3>
          <h2 class="text-6xl font-bold sm:text-6xl text-white">
            Grow your audience
          </h2>

          <p class="mt-4  text-gray-100">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut qui
            hic atque tenetur quis eius quos ea neque sunt,
          </p>
        </div> */}
        <Link to={linkto}>
          <a
            class={`block pt-7 rounded-xl border h-60 px-6 ${style} bg-dark-blue border-gray-800 p-4 shadow-xl transition hover:border-emerald-500/50 hover:shadow-heading-blue/50`}
          >
            <div className="flex">
              <div className="flex basis-1/5 "> {content.logo}</div>

              <div className="flex basis-4/5 justify-left">
                <h2 class="mt-4 text-[20px] font-bold text-left  text-heading-blue pb-2">
                  {content.service}
                </h2>
              </div>
            </div>
            <p class="mt-1 text-[15px] text-gray-300 ">{content.details}</p>
          </a>
        </Link>
      </div>

      {/* <div class="mt-12 text-center">
        <a
          href="#"
          class="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
        >
          Get Started Today
        </a>
      </div> */}
    </section>
  );
};

export default Service_card;
