import React from "react";

const Stepper_right = ({ content, language }) => {
  const stepWidth = language === "ar" ? "w-[13rem]" : "w-[15.5rem]";
  return (
    <div className="mx-auto mb-16 xs:mb-8">
      {/* <div className="hidden lg:flex xl:flex absolute inset-0 border-l-4 ml-[214px] h-[73%] border-emarld-700 after:border-red-500 before:border-blue-600"></div> */}
      <div className="xs:hidden flex flex-col mx-auto w-[80%]  text-center justify-center place-self-center">
        <div className="flex w-[70%] text-left  place-self-left pr-22">
          <div
            className={` ${stepWidth} h-[100px] mt-5 py-5 border border-emerald-800 rounded-[100%] mr-4 uppercase flex flex-col items-center justify-center bg-gradient-to-l from-emarld-700 to-green-500 shadow-md shadow-emerald-700`}
          >
            <div class="text-[16px] font-black text-white">
              {content.stepCount}
            </div>
          </div>
          <div class="flex-auto text-left  ">
            <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500"></div>
            <div class="py-1 text-3xl   text-emarld-700 font-extrabold ">
              {content.title}
            </div>
            <div class=" pt-1 pb-0 text-white">{content.description}</div>
          </div>
        </div>

        <div className="block mt-5 mx-auto">
          <img
            src={content.image}
            width={800}
            className="rounded-lg"
            loading="lazy"
          />
        </div>
      </div>

      {/* stepper start from here 01  */}
      <div class="hidden xs:flex xs:flex-row">
        <div class="flex-auto rounded  shadow-xl pb- xs:pb-0">
          <div class="flex md:flex-row flex-col items-center">
            <div class="flex-auto mx-auto ">
              <div class="md:hidden text-sm font-normal uppercase pt-3 pl-3 text-gray-500"></div>
              <div class="p-3 text-3xl   text-emarld-700 font-extrabold bg-gradient-to-b from-middark-blue to-transparent">
                {content.title}
              </div>
              <div class="px-3 pt-1 pb-6 text-white">{content.description}</div>
            </div>
            <div>
              <div className="hidden xs:flex ">
                <div className="p-3  rounded-[100%] xs:mb-1 bg-gradient-to-l from-emarld-700 to-green-500 text-white font-bold">
                  {content.mobileCount}
                </div>
              </div>
              <img
                src={content.image}
                width={1000}
                className="rounded-lg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="hidden  xs:flex">
      <img src={arrow} width={50} className="hidden xs:flex mt-3 mx-auto" />
    </div> */}
    </div>
  );
};

export default Stepper_right;
