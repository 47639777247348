import React from "react";

const Pageheader = ({ header, Image }) => {
  return (
    <div className=" w-full bg-transparent h-72 xs:h-36 relative">
      <div
        className={`absolute inset-0 bg-cover bg-center xs:bg-cover xs:bg-top bg-no-repeat ${Image} h-5/5`}
      ></div>
      <div className="absolute inset-0 bg-cover bg-gradient-to-b from-black to-dark-blue opacity-80 h-5/5"></div>
      <div className="text-white flex text-3xl lg:text-5xl font-bold items-center absolute inset-0  text-center content-center self-center justify-center h-5/5">
        <h1 className="mt-10">{header}</h1>
      </div>
    </div>
  );
};

export default Pageheader;
