import React from "react";
import Chooseus from "./components/Chooseus";
import Rating from "./components/svg_icon/Rating";
import Support from "./components/svg_icon/Support";
import Guard from "./components/svg_icon/Guard";
import Trophy from "./components/svg_icon/Trophy";
import Button from "./components/Button";
import { useTranslation } from "react-i18next";

const Whyus = (props) => {
  const { t } = useTranslation();

  return (
    <div className=" relative py-16 xs:py-10 shadow-[inset_0_-2px_200px_rgba(0,0,0,0.7)] xs:shadow-[inset_0_-2px_300px_rgba(0,0,0,0.9)]">
      <div className="absolute inset-0 bg-design-section opacity-25 bg-contain bg-no-repeat bg-right"></div>
      <div className=" relative flex lg:flex-row xxl:w-7/12 lg:w-9/12 mx-auto xs:w-full xs:flex-col md:flex-col lg:gap-14">
        <div class=" xs:px-3 text-left xs:text-center basis-5/12 md:basis-12/12 justify-center self-center">
          <h3 class="text-xl  font-bold xs:text-mini-heading-mobile text-emarld-700 text-left xs:text-center">
            {t("WHY CHOOSE US")}
          </h3>
          <h2 class="lg:text-subheading-font-mini xs:text-subheading-font-mobile capitalize  leading-tight xs:leading-10 xs:pb-5 font-semibold xs:text-2xl  text-heading-blue xs:text-center text-left mt-4">
            {t("See The Difference")}
          </h2>
          <p className="text-white lg:mt-6 text-left text-pragraph-font xs:text-center xs:mt-3">
            {t("choose us body")}
          </p>
          <Button
            content={{ name: t("More Information") }}
            linkTo={"/contact-us"}
          />
        </div>
        <section class=" basis-7/12 md:basis-10/12 text-white w-3/4 xs:mt-8 xs:w-full mx-auto content-stretch relative">
          <div class="mt-8 grid grid-cols-1 gap-x-16 md:grid-cols-1 lg:grid-cols-2">
            <div className="m-10 xs:m-0">
              <Chooseus
                content={{
                  logo: <Guard />,
                  service: t("why1"),
                  details: t("why body1"),
                }}
              ></Chooseus>
              <Chooseus
                content={{
                  logo: <Rating />,
                  service: t("why3"),
                  details: t("why body3"),
                }}
              ></Chooseus>
            </div>
            <div>
              <Chooseus
                content={{
                  logo: <Support />,
                  service: t("why2"),
                  details: t("why body2"),
                }}
              ></Chooseus>
              <Chooseus
                content={{
                  logo: <Trophy />,
                  service: t("why4"),
                  details: t("why body4"),
                }}
              ></Chooseus>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Whyus;
