import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "../locales/en/english.json";
import arTranslations from "../locales/ar/translation.json";

// the translations
i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // Default language
  resources: {
    en: { translation: enTranslations },
    ar: { translation: arTranslations },
  },
});

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: "en", // default language
//     fallbackLng: "en", // fallback language
//     interpolation: {
//       escapeValue: false, // react already safes from xss
//     },
//   });

export default i18n;
