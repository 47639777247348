import React from "react";

const Trophy = (props) => {
  return (
    <div>
      <svg
        version="1.1"
        id="TROPHY"
        width="40px"
        height="40px"
        viewBox="0 0 1800 1800"
        enableBackground="new 0 0 1800 1800"
      >
        <g>
          <path
            fill="#35D399"
            d="M1680.014,241.388c-5.876-5.976-13.907-9.342-22.291-9.342h-265.229v-45.949
		c1.754-3.904,2.748-8.221,2.748-12.777s-0.994-8.873-2.748-12.777V39.669c0-17.271-13.998-31.269-31.269-31.269H435.739
		c-17.271,0-31.269,13.998-31.269,31.269v192.377H142.277c-8.384,0-16.415,3.366-22.291,9.342
		c-5.88,5.977-9.112,14.064-8.973,22.446c2.543,153.317,35.5,297.176,92.802,405.076c59.797,112.598,140.534,175.43,227.77,177.55
		c51.846,154.953,178.085,274.968,335.409,319.092v235.304c-64.348,36.395-110.3,98.631-122.317,172.335H436.145
		c-17.27,0-31.269,13.994-31.269,31.269v155.872c0,17.266,13.999,31.269,31.269,31.269h927.711c17.27,0,31.269-14.003,31.269-31.269
		v-155.872c0-17.274-13.999-31.269-31.269-31.269h-208.533c-12.018-73.704-57.97-135.94-122.317-172.335v-234.902
		c158.275-43.941,284.886-164.749,336.9-319.526c86.665-2.796,166.826-65.56,226.278-177.517
		c57.303-107.9,90.26-251.759,92.803-405.076C1689.126,255.452,1685.894,247.364,1680.014,241.388z M404.876,780.025
		c-53.494-13.72-104.375-62.389-145.83-140.445c-48.844-91.976-78.495-213.492-84.514-344.997h229.938v420.416
		c0,1.625,0.166,3.209,0.405,4.768V780.025z M1332.587,1635.728v93.335H467.413v-93.335h205.479h454.215H1332.587z
		 M1091.708,1573.19H708.292c18.728-83.109,97.165-144.329,191.708-144.329S1072.98,1490.081,1091.708,1573.19z M829.532,1375.485
		v-196.677c23.286,3.368,47.082,5.112,71.266,5.112c23.648,0,46.899-1.71,69.669-4.929v196.493
		c-22.392-5.934-46.009-9.161-70.468-9.161S851.924,1369.552,829.532,1375.485z M1332.587,707.252
		c-9.506,229.967-199.529,414.132-431.789,414.132c-229.411,0-418.955-179.262-431.518-408.099c-0.17-3.104-0.863-6.049-1.867-8.838
		V263.314c0-1.636-0.161-3.23-0.405-4.798V70.938h862.948v71.113H705.099c-17.27,0-31.269,13.999-31.269,31.269
		c0,17.271,13.999,31.269,31.269,31.269h624.857v431.585c0,1.182,0.065,2.362,0.201,3.536c1.212,10.64,1.993,21.318,2.43,31.934
		V707.252z M1540.954,639.58c-41.455,78.056-92.336,126.726-145.83,140.445v-71.698c0.244-6.354,0.405-12.729,0.405-19.142
		c0-6.214-0.166-12.476-0.405-18.753v-375.85h230.344C1619.448,426.088,1589.798,547.604,1540.954,639.58z"
            stroke="#35D399"
            strokeWidth="20"
          />
          <path
            fill="#35D399"
            d="M1123.914,493.53l-135.348-19.667l-60.525-122.645c-5.27-10.675-16.141-17.432-28.041-17.432
		s-22.771,6.757-28.041,17.432l-60.526,122.645L676.086,493.53c-11.778,1.712-21.563,9.961-25.24,21.281
		c-3.677,11.32-0.61,23.746,7.913,32.054l97.933,95.463l-23.12,134.8c-2.011,11.729,2.81,23.586,12.441,30.581
		c9.623,6.991,22.392,7.924,32.926,2.38L900,746.447l121.062,63.643c4.571,2.405,9.566,3.592,14.543,3.592
		c6.487,0,12.938-2.013,18.383-5.972c9.632-6.995,14.452-18.852,12.441-30.581l-23.12-134.8l97.933-95.463
		c8.523-8.308,11.59-20.734,7.913-32.054C1145.477,503.491,1135.692,495.242,1123.914,493.53z M987.886,609.021
		c-7.372,7.185-10.735,17.534-8.994,27.677l15.188,88.558l-79.532-41.81c-4.554-2.395-9.549-3.592-14.548-3.592
		s-9.994,1.197-14.548,3.592l-79.533,41.81l15.189-88.558c1.741-10.143-1.622-20.492-8.995-27.677l-64.334-62.714l88.916-12.921
		c10.186-1.481,18.988-7.876,23.543-17.106L900,435.711l39.762,80.568c4.555,9.23,13.357,15.625,23.543,17.106l88.916,12.921
		L987.886,609.021z"
            stroke="#35D399"
            strokeWidth="20"
          />
        </g>
      </svg>
    </div>
  );
};

export default Trophy;
