import React from "react";

const Rating = (props) => {
  return (
    <div>
      <svg
        fill="#35D399"
        height="40px"
        width="40px"
        version="1.1"
        id="Layer_1"
        viewBox="0 0 496 496"
      >
        <g>
          <g>
            <g>
              <path
                d="M496,112C496,50.24,445.76,0,384,0H112C50.24,0,0,50.24,0,112s50.24,112,112,112h21.984
				C104.56,253.784,88,293.552,88,336c0,88.224,71.776,160,160,160s160-71.776,160-160c0-42.448-16.56-82.216-45.984-112H384
				C445.76,224,496,173.76,496,112z M320,460.512C298.792,472.832,274.24,480,248,480s-50.792-7.168-72-19.488V440
				c0-39.696,32.296-72,72-72s72,32.304,72,72V460.512z M392,336c0,46.248-22.008,87.36-56,113.728V440c0-48.52-39.48-88-88-88
				c-48.52,0-88,39.48-88,88v9.728c-33.992-26.368-56-67.48-56-113.728c0-43.8,19.592-84.448,53.8-112h45.048
				C186.496,237.208,176,257.392,176,280c0,39.696,32.296,72,72,72s72-32.304,72-72c0-22.608-10.496-42.792-26.848-56H338.2
				C372.408,251.552,392,292.2,392,336z M248,224c30.872,0,56,25.12,56,56s-25.128,56-56,56s-56-25.12-56-56S217.128,224,248,224z
				 M112,208c-52.936,0-96-43.064-96-96s43.064-96,96-96h272c52.936,0,96,43.064,96,96c0,52.936-43.064,96-96,96H112z"
                stroke="#35D399"
                strokeWidth="20"
              />
              <path
                d="M392,149.88l44.528,23.416l-8.504-49.592l36.04-35.12l-49.8-7.232L392,36.232l-22.264,45.12L320,88.576l-49.736-7.224
				L248,36.232l-22.264,45.12L176,88.576l-49.736-7.224L104,36.232l-22.264,45.12l-49.8,7.232l36.04,35.12l-8.504,49.592L104,149.88
				l44.528,23.416l-8.504-49.592L176,88.648l35.976,35.064l-8.504,49.592L248,149.88l44.528,23.416l-8.504-49.592L320,88.648
				l35.976,35.064l-8.504,49.592L392,149.88z M122.832,118.12l4.448,25.928L104,131.808l-23.28,12.24l4.448-25.928l-18.84-18.36
				l26.032-3.784L104,72.392l11.64,23.584l26.032,3.784L122.832,118.12z M266.84,118.12l4.448,25.928L248,131.808l-23.28,12.24
				l4.448-25.928l-18.84-18.36l26.032-3.784L248,72.392l11.64,23.584l26.032,3.784L266.84,118.12z M354.336,99.76l26.024-3.784
				L392,72.392l11.64,23.584l26.032,3.784l-18.832,18.36l4.448,25.928L392,131.808l-23.28,12.24l4.448-25.928L354.336,99.76z"
                stroke="#35D399"
                strokeWidth="10"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Rating;
