import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Pagerouters from "./Pagerouters";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Price from "./pages/Price";
import Faq from "./pages/Faq";
import Servicepage from "./pages/Servicepage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App bg-middark-blue">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/pricing" element={<Price />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/services" element={<Servicepage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
    // <div className="App bg-middark-blue">
    //   <Home></Home>
    // </div>
  );
}

export default App;
