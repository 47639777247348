import React from "react";

const Chooseus = ({ content }) => {
  return (
    <section class=" text-white ">
      <div class="mx-auto   px-4 py-8 sm:px-6 sm:py-12 lg:px-4 lg:py-4 h-auto">
        {/* <div class="lg:py-24 text-left">
          <h3 class="text-xl font-bold sm:text-xl text-emarld-700 ">
            ABOUT US
          </h3>
          <h2 class="text-6xl font-bold sm:text-6xl text-white">
            Grow your audience
          </h2>

          <p class="mt-4  text-gray-100">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut qui
            hic atque tenetur quis eius quos ea neque sunt,
          </p>
        </div> */}

        <a class="block xs:w-full rounded-xl border text-left xs:h-56 h-64 w-80  bg-dark-blue border-gray-800 p-5 shadow-xl transition hover:border-emerald-500/50 hover:shadow-heading-blue/50">
          {content.logo}

          <h2 class="mt-4 text-xl font-bold  text-heading-blue">
            {content.service}
          </h2>

          <p class="mt-1 text-[16px] text-gray-300">{content.details}</p>
        </a>
      </div>

      {/* <div class="mt-12 text-center">
        <a
          href="#"
          class="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
        >
          Get Started Today
        </a>
      </div> */}
    </section>
  );
};

export default Chooseus;
