import React from "react";
import Service_card from "./components/Service_card";
import werehouse from "./Images/service/warehouse.svg";
import Logistic from "./components/svg_icon/Logistic";
import Shipping from "./components/svg_icon/Shipping";
import Werehouse from "./components/svg_icon/Werehouse";
import Procument from "./components/svg_icon/Procurment";
import Analytics from "./components/svg_icon/Analytics";
import Team from "./components/svg_icon/Team";
import { useTranslation } from "react-i18next";

const Services = (props) => {
  const { t } = useTranslation();
  return (
    <div className=" relative py-28  xs:py-20 shadow-[inset_0_-2px_200px_rgba(0,0,0,0.7)]">
      <div className="absolute inset-0 bg-design-section opacity-25 bg-contain bg-no-repeat bg-right"></div>
      <div class=" text-center">
        <h3 class="text-xl xs:text-mini-heading-mobile font-bold sm:text-xl text-emarld-700 ">
          {t("Services")}
        </h3>
        <h2 class="mt-3  text-6xl xs:text-subheading-font-mobile  lg:text-subheading-font font-semibold sm:text-6xl capitalize  text-heading-blue">
          {t("We Provides")}
        </h2>
      </div>
      <section class=" text-white xl:w-[80%] xxl:w-[60%] xs:w-full mx-auto content-stretch relative rtl:mr-10">
        <div class="mt-8 grid grid-cols-1 gap-0 md:grid-cols-2 lg:grid-cols-3 xs:px-2">
          <Service_card
            content={{
              logo: <Logistic />,
              service: t("Logistics"),
              details: t("services1"),
            }}
            linkto="/services"
          ></Service_card>
          <Service_card
            content={{
              logo: <Shipping />,
              service: t("Cross Border Shiping"),
              details: t("services2"),
            }}
            linkto="/services"
          ></Service_card>
          <Service_card
            content={{
              logo: <Werehouse />,
              service: t("Warehouse Solutions"),
              details: t("services3"),
            }}
            linkto="/services"
          ></Service_card>
          <Service_card
            content={{
              logo: <Procument />,
              service: t("Procurement"),
              details: t("services4"),
            }}
            linkto="/services"
          ></Service_card>
          <Service_card
            content={{
              logo: <Analytics />,
              service: t("Audit & Analytics"),
              details: t("services5"),
            }}
            linkto="/services"
          ></Service_card>
          <Service_card
            content={{
              logo: <Team />,
              service: t("Team Outsourcing"),
              details: t("services6"),
            }}
            linkto="/services"
          ></Service_card>
          {/* <div className="  lg:grid-cols-subgrid lg:col-span-3">
            <div className="lg:col-start-2 xs:bg-middark-blue ">
              <Service_card
                content={{
                  logo: <Team />,
                  service: "Team Outsourcing",
                  details:
                    "3PL Links offers complete turnkey project management services that act as an extension or stand-in for your existing Operations team. Proven methodology combined with expertise in carrier relations, strong partnerships, customer service, communication, and technology will prove to be extremely valuable.",
                }}
                linkto="/services"
                style="xs:pb-80"
              ></Service_card>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default Services;
