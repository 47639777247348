import React from "react";
import Testimonial_card from "./components/Testmonial_card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import bannerImage from "./Images/client_logo/truckEmreld.png";

const Testimonial = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 5500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          initialSlide: 0,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          infinite: true,
          speed: 500,
        },
      },
    ],
  };
  return (
    <div className="max-w-7xl xs:w-5/5 mx-auto py-28 xs:pb-14">
      <div class=" text-center pb-16 xs:pb-3">
        <h3 class="text-xl xs:text-mini-heading-mobile lg:text-mini-heading font-bold sm:text-xl text-emarld-700 ">
          Testimonial
        </h3>
        <h2 class="text-6xl xs:text-subheading-font-mobile lg:text-subheading-font font-bold sm:text-6xl  text-heading-blue">
          Clients Feedback
        </h2>
      </div>
      <Slider {...settings}>
        <Testimonial_card></Testimonial_card>

        <Testimonial_card></Testimonial_card>

        <Testimonial_card></Testimonial_card>

        <Testimonial_card></Testimonial_card>

        <Testimonial_card></Testimonial_card>
      </Slider>
    </div>
  );
};

export default Testimonial;
