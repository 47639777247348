import React from "react";
import clientimage01 from "./Images/client_logo/edited1.png";
import clientimage02 from "./Images/client_logo/2.png";
import clientimage03 from "./Images/client_logo/edited3.png";
import clientimage04 from "./Images/client_logo/edited4.png";
import clientimage05 from "./Images/client_logo/edited5.png";
import clientimage06 from "./Images/client_logo/6.png";
import { useTranslation } from "react-i18next";

const Client_logo = (props) => {
  const { t } = useTranslation();

  return (
    <div className=" pb-24 xs:pb-0 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-semibold leading-8 capitalize text-green-500 xs:text-2xl xs:pt-16">
          {t("We Are Trusted")}
        </h2>
        <div className=" mx-auto mt-10 grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-10 sm:max-w-xl xs:grid-cols-2  sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-2 place-items-center">
          {/* <img
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1 "
            src={clientimage01}
            alt="Transistor"
            width={158}
            height={48}
          /> */}
          <img
            className="col-span-2 xs:col-span-1 max-h-14  xs:aspect-[2/1] place-items-center  object-contain lg:col-span-1 w-[178px] items-center"
            src={clientimage02}
            alt="Reform"
            width={158}
            height={48}
            loading="lazy"
          />
          {/* <img
            className="col-span-2 xs:col-span-1 max-h-16 w-full  xs:aspect-[4/3] object-contain lg:col-span-1"
            src={clientimage03}
            alt="Tuple"
            width={158}
            height={48}
            loading="lazy"
          />
          <img
            className="col-span-2 xs:col-span-1 max-h-16 w-full  xs:aspect-[2/1] object-contain sm:col-start-2 lg:col-span-1"
            src={clientimage04}
            alt="SavvyCal"
            width={148}
            height={40}
            loading="lazy"
          /> */}
          <img
            className="col-span-2 xs:col-span-1 col-start-2 max-h-25 place-items-center object-contain sm:col-start-auto lg:col-span-1 w-[178px]"
            src={clientimage05}
            alt="Statamic"
            width={158}
            height={48}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Client_logo;
