import React from "react";
import Button_arrow from "./svg_icon/Button_arrow";
import { Link } from "react-router-dom";

const Button = ({ content, linkTo }) => {
  return (
    <div className="mt-10">
      <Link to={linkTo}>
        <a className=" text-xl inline-flex xs:px-12 xs:py-4 items-center  rounded-md shadow-md shadow-green-500 bg-gradient-to-r from-teal-600 to-emerald-400 px-20 py-4 xs:text-sm font-semibold text-white   hover:bg-gradient-to-b hover:from-emerald-400 hover:to-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          {content.name}
        </a>
      </Link>
    </div>
  );
};

export default Button;
