// import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useTranslation } from "react-i18next";

// const Contactform = (props) => {
//   const { t } = useTranslation();
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     message: "",
//   });
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     // console.log("first name", formData.firstName);
//     // console.log("last name", formData.lastName);
//     // console.log("email", formData.email);
//     // console.log("phone", formData.phone);
//     // console.log("message", formData.message);

//     e.preventDefault();
//     // const apiUrl = "http://localhost:5000/api/formcreate";
//     const apiUrl = "https://redexbackend.teammatrixmantra.com/api/formcreate";

//     try {
//       setIsSubmitting(true);
//       const response = await fetch(apiUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });
//       if (response.ok) {
//         // Handle success
//         // console.log("Form data submitted successfully");
//         // Reset form fields after submission
//         setFormData({
//           firstName: "",
//           lastName: "",
//           email: "",
//           phone: "",
//           message: "",
//         });
//         toast.success("Form submitted successfully!");
//         setTimeout(() => {
//           setIsSubmitting(false);
//         }, 3000);
//       } else {
//         // Handle errors
//         console.error("Failed to submit form data");
//         setTimeout(() => {
//           setIsSubmitting(false);
//         }, 3000);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setIsSubmitting(false);
//     }
//   };

//   useEffect(() => {
//     // Scroll to the top of the page when component mounts
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <div className="relative">
//       <div className="absolute inset-0   bg-design-section opacity-10 bg-contain  bg-repeat-y bg-right -z-9"></div>

//       <div class="flex justify-center items-center xs:w-11/12 xl:w-11/12 w-11/12 mx-auto  bg-transparent py-24 xs:py-4">
//         {/* <!-- COMPONENT CODE --> */}
//         <form onSubmit={handleSubmit}>
//           <div class="container mx-auto my-4 px-4 xs:px-0 imac:px-0 lg:px-20 ">
//             <div class=" flex flex-col basis-6/12  p-8 my-4 md:px-12 lg:w-7/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl ">
//               <div class="flex text-center">
//                 <h1 class="font-bold uppercase text-3xl  xs:text-3xl self-center items-center content-center text-emarld-700 ">
//                   {t("sent message")}
//                 </h1>
//               </div>
//               <div class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
//                 <input
//                   class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                   type="text"
//                   name="firstName"
//                   value={formData.firstName}
//                   onChange={handleChange}
//                   required
//                   placeholder={t("firstname")}
//                 />
//                 <input
//                   class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                   type="text"
//                   name="lastName"
//                   placeholder={t("lastname")}
//                   value={formData.lastName}
//                   onChange={handleChange}
//                   required
//                 />
//                 <input
//                   class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                   type="email"
//                   placeholder={t("email")}
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   required
//                 />
//                 <input
//                   class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                   type="number"
//                   placeholder={t("phone")}
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//               <div class="my-4">
//                 <textarea
//                   placeholder={t("message body")}
//                   name="message"
//                   value={formData.message}
//                   onChange={handleChange}
//                   required
//                   class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                 ></textarea>
//               </div>
//               <div class="my-2 w-1/2 xs:w-full lg:w-1/4">
//                 <button
//                   type="submit"
//                   disabled={isSubmitting} // Disable button if submitting
//                   className={`uppercasehover:bg-gradient-to-b hover:from-emerald-400 hover:to-teal-600 text-sm inline-flex items-center place-content-center  rounded-md shadow-md shadow-green-500 bg-gradient-to-r from-teal-600 to-emerald-400 w-40 py-3 text-center  xs:text-sm font-semibold text-white   focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
//                     isSubmitting ? "opacity-50 cursor-not-allowed" : ""
//                   }`}
//                 >
//                   {isSubmitting ? t("senting") : t("sent")}
//                 </button>
//               </div>
//             </div>

//             <div class=" flex basis-5/12 lg:-mt-[400px] lg:w-7/12 px-8 py-2 ml-[50%] xs:ml-0   rounded-2xl">
//               <div class="flex flex-col text-emarld-700">
//                 <h1 class="font-bold uppercase text-3xl my-6 lg:text-[22px] xxl:text-[25px] ">
//                   {t("Drop in our office")}
//                 </h1>

//                 <div class="grid grid-cols-2  my-4 w-full  text-left xs:grid-cols-1 z-10">
//                   <div className="flex flex-col">
//                     <div class="h-[12rem]  xl:h-[14rem] flex p-6 ring-1 ring-dark-blue rounded-md mr-4 mb-5 xs:mr-0 bg-dark-blue border-gray-800 shadow-xl transition hover:border-emerald-500/50 hover:shadow-heading-blue/50 shadow-emarld-700/10">
//                       <div class="flex flex-col text-left">
//                         <h2 class="text-2xl lg:text-xl xxl:text-2xl mb-2 font-bold text-heading-blue">
//                           KSA
//                         </h2>
//                         <p class="text-gray-200 lg:text-sm xxl:text-lg capitalize">
//                           <i class="fas fa-map-marker-alt text-emarld-700 pr-2"></i>
//                           {t("Ksa_address")}
//                           <br />
//                           <span className="capitalize">
//                             {" "}
//                             <i class="fas fa-globe text-emarld-700 pr-4"></i>
//                             {t("kas")}
//                           </span>
//                         </p>
//                         <p>
//                           <i class="fas fa-phone-alt text-emarld-700 pr-1"></i>{" "}
//                           +966562097145
//                         </p>
//                       </div>
//                     </div>

//                     <div class="h-[12rem]  xl:h-[14rem] flex p-6 ring-1 ring-dark-blue rounded-md mr-4 mb-5 xs:mr-0 bg-dark-blue border-gray-800 shadow-xl transition hover:border-emerald-500/50 hover:shadow-heading-blue/50 shadow-emarld-700/10">
//                       <div class="flex flex-col text-left">
//                         <h2 class="text-2xl mb-2 lg:text-xl xxl:text-2xl font-bold text-heading-blue">
//                           UAE
//                         </h2>
//                         <p class="text-gray-200 lg:text-sm xxl:text-lg capitalize">
//                           <i class="fas fa-map-marker-alt text-emarld-700 pr-2"></i>
//                           {t("uae_address")}
//                           <br />
//                           <span className="capitalize">
//                             {" "}
//                             <i class="fas fa-globe text-emarld-700 pr-4"></i>
//                             {t("uae")}
//                           </span>
//                         </p>
//                         <p>
//                           <i class="fas fa-phone-alt text-emarld-700 pr-1"></i>{" "}
//                           +971509817551
//                         </p>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex flex-col -mt-7 xs:mt-0">
//                     <div class="lg:ml-4 h-[12rem]  xl:h-[14rem] flex p-6 ring-1 ring-dark-blue rounded-md mb-5 xs:mr-0 bg-dark-blue border-gray-800 shadow-xl transition hover:border-emerald-500/50 hover:shadow-heading-blue/50 shadow-emarld-700/10">
//                       <div class="flex flex-col text-left">
//                         <h2 class="text-2xl mb-2 lg:text-xl xxl:text-2xl font-bold text-heading-blue">
//                           QAR
//                         </h2>
//                         <p class="text-gray-200 lg:text-sm xxl:text-lg capitalize">
//                           <i class="fas fa-map-marker-alt text-emarld-700 pr-2"></i>
//                           {t("qar_address")}
//                           <br />
//                           <span className="capitalize">
//                             <i class="fas fa-globe text-emarld-700 pr-4"></i>
//                             {t("qar")}
//                           </span>
//                         </p>
//                         <p>
//                           <i class="fas fa-phone-alt text-emarld-700 pr-1"></i>{" "}
//                           +97433215223
//                         </p>
//                       </div>
//                     </div>

//                     <div class="lg:ml-4 h-[12rem]  xl:h-[14rem] flex p-6 ring-1 ring-dark-blue rounded-md mr-0 mb-5 xs:mr-0 bg-dark-blue border-gray-800 shadow-xl transition hover:border-emerald-500/50 hover:shadow-heading-blue/50 shadow-emarld-700/10">
//                       <div class="flex flex-col text-left">
//                         <h2 class="text-2xl mb-2 lg:text-xl xxl:text-2xl font-bold text-heading-blue">
//                           LK
//                         </h2>
//                         <p class="text-gray-200 lg:text-sm xxl:text-lg capitalize">
//                           <i class="fas fa-map-marker-alt text-emarld-700 pr-2"></i>
//                           {t("lk_address")}
//                           <br />
//                           <span className="capitalize">
//                             {" "}
//                             <i class="fas fa-globe text-emarld-700 pr-4"></i>
//                             {t("lk")}
//                           </span>
//                         </p>
//                         <p>
//                           <i class="fas fa-phone-alt text-emarld-700 pr-1"></i>{" "}
//                           +94771144444
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Contactform;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const Contactform = (props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    // console.log("first name", formData.firstName);
    // console.log("last name", formData.lastName);
    // console.log("email", formData.email);
    // console.log("phone", formData.phone);
    // console.log("message", formData.message);

    e.preventDefault();
    // const apiUrl = "http://localhost:5000/api/formcreate";
    const apiUrl = "https://redexbackend.teammatrixmantra.com/api/formcreate";

    try {
      setIsSubmitting(true);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        // Handle success
        // console.log("Form data submitted successfully");
        // Reset form fields after submission
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
        toast.success("Form submitted successfully!");
        setTimeout(() => {
          setIsSubmitting(false);
        }, 3000);
      } else {
        // Handle errors
        console.error("Failed to submit form data");
        setTimeout(() => {
          setIsSubmitting(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="relative">
      <div className="absolute inset-0   bg-design-section opacity-10 bg-contain  bg-repeat-y bg-right -z-9"></div>

      <div class="flex justify-center items-center xs:w-11/12 xl:w-11/12 w-11/12 mx-auto  bg-transparent py-24 xs:py-4 z-20 relative">
        {/* <!-- COMPONENT CODE --> */}
        <form onSubmit={handleSubmit}>
          {/* <div class="container mx-auto my-4 px-4 xs:px-0 imac:px-0 lg:px-20 "> */}
          <div class="flex col-span-2 xs:flex-col xs:col-span-1">
            {/* <div class=" flex flex-col basis-12/12  p-8 my-4 md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl "> */}
            <div class=" flex flex-col basis-8/12  p-8 my-4 md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl ">
              <div class="flex text-center">
                <h1 class="font-bold uppercase text-3xl  xs:text-3xl self-center items-center content-center text-emarld-700 ">
                  {t("sent message")}
                </h1>
              </div>
              <div class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                <input
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  placeholder={t("firstname")}
                />
                <input
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  name="lastName"
                  placeholder={t("lastname")}
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <input
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="email"
                  placeholder={t("email")}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <input
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder={t("phone")}
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="my-4">
                <textarea
                  placeholder={t("message body")}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                ></textarea>
              </div>
              <div class="my-2 w-1/2 xs:w-full lg:w-1/4">
                <button
                  type="submit"
                  disabled={isSubmitting} // Disable button if submitting
                  className={`uppercasehover:bg-gradient-to-b hover:from-emerald-400 hover:to-teal-600 text-sm inline-flex items-center place-content-center  rounded-md shadow-md shadow-green-500 bg-gradient-to-r from-teal-600 to-emerald-400 w-40 py-3 text-center  xs:text-sm font-semibold text-white   focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isSubmitting ? t("senting") : t("sent")}
                </button>
              </div>
            </div>

            {/* <div class=" flex basis-12/12 lg:-mt-[400px] lg:w-12/12 px-8 py-2 ml-[50%] xs:ml-0   rounded-2xl"> */}
            <div class=" flex  basis-2/6  rounded-2xl mt-16">
              <div class="flex flex-col text-emarld-700">
                <h1 class="font-bold uppercase text-3xl my-6 lg:text-[22px] xxl:text-[25px] text-left ml-4">
                  {t("Drop in our office")}
                </h1>

                <div class="flex my-4 w-full  text-left xs:grid-cols-1 z-10">
                  <div class="lg:ml-4 h-[12rem]  xl:h-[14rem] flex p-6 ring-1 ring-dark-blue rounded-md mr-0 mb-5 xs:mr-0 bg-dark-blue border-gray-800 shadow-xl transition hover:border-emerald-500/50 hover:shadow-heading-blue/50 shadow-emarld-700/10">
                    <div class="flex flex-col text-left">
                      <h2 class="text-2xl mb-2 lg:text-xl xxl:text-2xl font-bold text-heading-blue">
                        LK
                      </h2>
                      <p class="text-gray-200 lg:text-sm xxl:text-lg capitalize">
                        <i class="fas fa-map-marker-alt text-emarld-700 pr-2"></i>
                        {t("lk_address")}
                        <br />
                        <span className="capitalize">
                          {" "}
                          <i class="fas fa-globe text-emarld-700 pr-4"></i>
                          {t("lk")}
                        </span>
                      </p>
                      <p>
                        <i class="fas fa-phone-alt text-emarld-700 pr-1"></i>{" "}
                        +94771144444
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contactform;
