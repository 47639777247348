import React from "react";
import bannerImage from "./Images/client_logo/truckEmreld.webp";
import Button from "./components/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Banner = (props) => {
  const { t } = useTranslation();
  return (
    <div className="relative py-28 xs:pb-0">
      <div className="absolute inset-0 bg-contain xs:bg-cover xs:bg-top bg-no-repeat  bg-hero-banner bg-blend-color-burn"></div>
      <div className="absolute inset-0 bg-gradient-to-br from-dark-blue to-black opacity-90 bg-blend-overlay "></div>
      <div className="flex xs:flex-col lg:ml-60 z-10 relative gap-10">
        {/* <div className="inset-0 object-contain bg-hero-banner bg-no-repeat bg-cover ">
        <div className="inset-0 bg-gradient-to-r from-purple-dark to-purple-light  opacity-[95%]">
          <div className=" isolate px-6 pt-14 lg:px-8">
            <div
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-dark-voilet to-dark-black opacity-10 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div className="relative mx-auto max-w-2xl py-10 sm:py-48 lg:py-56">
              <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-slate-50 ring-1 ring-slate-300 hover:ring-gray-900/20">
                  Welcome To RedEx{" "}
                  <a href="#" className="font-semibold text-white"></a>
                </div>
              </div>
              <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  Data to enrich your online business
                </h1>
                <p className="mt-6 text-lg leading-8 text-slate-200">
                  Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure
                  qui lorem cupidatat commodo. Elit sunt amet fugiat veniam
                  occaecat fugiat aliqua.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-dark-voilet ring-1 ring-purple-700 px-9 py-4 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <div
              className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-dark-voilet to-[#470606] opacity-50 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem] -mt-72"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
        <div className=" text-left xs:text-center text-white self-center basis-7/12 xs:w-11/12 xs:mx-auto">
          <h3 className="text-[17px]  xs:text-[12px] text-emarld-700 font-semibold">
            {t("START YOUR DIGITAL")}
          </h3>
          <h2 className="text-4xl xs:text-3xl   font-medium leading-tight xs:mt-4 mt-8">
            {t("Help Your Business")}
          </h2>
          <p className="mt-8 xs:mt-4 ">{t("Redex")}</p>
          <div className=" flex gap-x-6 xs:gap-x-0 xs:justify-center xs:mx-auto">
            <Button
              content={{ name: t("discover more") }}
              linkTo={"/services"}
            ></Button>
          </div>
        </div>
        <div className=" basis-3/5 ">
          <img
            className="text-center w-full"
            src={bannerImage}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
