import React from "react";
import imag from "./Images/aboutus.webp";
import Button from "./components/Button";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="">
      <section className="w-9/12 mx-auto xs:w-11/12 ">
        <div class="mx-auto  px-4  sm:px-6 xs:pt-20 sm:py-12 lg:px-0 lg:py-0 lg:pb-10 xs:px-0 xs:pb-8">
          <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 xs:flex xs:flex-col-reverse">
            <div class="relative h-4/6 rounded-lg sm:h-80 xs:h-4/5 lg:order-last lg:h-full xs:self-end xs:pt-10 flex justify-center items-center">
              <img
                alt="Party"
                src={imag}
                class="  mx-auto   xs:h-full xs:w-80 inset-0 content-around w-5/5 object-contain "
                loading="lazy"
              />
            </div>

            <div class="lg:py-16 text-left">
              <h3 class="text-xl xs:text-mini-heading-mobile  font-bold sm:text-xl text-emarld-700 ">
                {t("aboutUs")}
              </h3>
              <h2 class="mt-3 text-xl lg:leading-snug lg:text-subheading-font font-semibold capitalize sm:text-6xl xs:text-subheading-font-mobile text-heading-blue  ">
                {t("Reduce Costs")}
              </h2>

              <p class="mt-4  text-gray-100">{t("aboutus body 1")}</p>
              <h2 class="mt-4  text-gray-100">{t("aboutus body 2")}</h2>

              <Button content={{ name: t("aboutUs") }} linkTo={"about-us"} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(AboutUs);
