import React from "react";
import ContactUs from "../ContactUs";
import Navbar from "../Navbar";
import Banner from "../Banner";
import Footer from "../Footer";
import Pageheader from "../components/Pageheader";
import Contactform from "../components/Contactform";
import { useTranslation } from "react-i18next";

const Contact = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar></Navbar>
      {/* <Banner></Banner> */}
      <Pageheader header={t("Contact Us")} Image="bg-hero-banner"></Pageheader>
      <Contactform></Contactform>
      {/* <ContactUs></ContactUs> */}
      <Footer></Footer>
    </div>
  );
};

export default Contact;
