import React from "react";

import Stepper_left from "./components/Stepper_left";
import Stepper_right from "./components/Stepper_right";
import Stepper_end from "./components/Stepper_end";
import { useTranslation } from "react-i18next";

// import recived from "./Images/Workflow/recived.json";
import service from "./Images/Workflow/1.webp";
import deal from "./Images/Workflow/2.webp";
import operate from "./Images/Workflow/3.webp";

const Stepper = (props) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return (
    <div className="relative mt-0 xs:mt-0 pb-7 xs:pt-16 xs:px-2 shadow-[inset_0_-2px_200px_rgba(0,0,0,0.7)] ]">
      <div className="bg-design-section  absolute inset-0 bg-auto bg-repeat-y transform rotate-0  scale-x-[-1]   bg-right opacity-25"></div>
      {/* <div className="bg-design-section  absolute inset-0 bg-auto bg-repeat-y transform rotate-0  bg-right opacity-25"></div> */}

      <div class="lg:py-10 text-center relative">
        <h3 class="text-xl xs:text-mini-heading-mobile font-bold sm:text-xl text-emarld-700 ">
          {t("wkflow")}
        </h3>
        <h2 class="mt-3 text-6xl xs:text-subheading-font-mobile lg:text-subheading-font   font-semibold sm:text-6xl  text-heading-blue">
          {t("Methods")}
        </h2>

        <p class="mt-4  text-gray-100">{t("wkflow body")}</p>
      </div>

      <section class="max-w-7xl mx-auto  py-0 relative xs:pt-16 w-[90%">
        <div>
          <div>
            <Stepper_left
              content={{
                stepCount: t("STEP 1 "),
                mobileCount: "S-1",
                title: t("REQUEST SERVICE"),
                description: t("STEP1 body"),
                // image: service,
                // width: "600px",
              }}
              language={language} // Pass language information
            />
          </div>

          <Stepper_right
            content={{
              stepCount: t("STEP 2  "),
              mobileCount: "S-2",
              title: t("SERVICE SELECTION"),
              description: t("STEP2 body"),

              // image: deal,
              // width: "600px",
            }}
            language={language} // Pass language information
          />

          {/* <Stepper_left
            content={{
              stepCount: "Step 3",
              title: "Send the quote",
              description: "SCL will send the quote with in 48 hours",
              animationData: sentquoto,
              width: "350px",
            }}
          />

          <Stepper_right
            content={{
              stepCount: "Step 4",
              title: "Client Accept the quote",
              description: "Client Accept the quote",
              animationData: client,
              width: "300px",
            }}
          />

          <Stepper_left
            content={{
              stepCount: "Step 5",
              title: "Sign the contract",
              description: "Sign the contract",
              animationData: sign,
              width: "300px",
            }}
          />

          <Stepper_right
            content={{
              stepCount: "Step 6",
              title: "Paid Advance as per the agreed contract",
              description: "Paid Advance as per the agreed contract",
              animationData: payment,
              width: "300px",
            }}
          /> */}

          <Stepper_left
            content={{
              stepCount: t("STEP 3 "),
              mobileCount: "S-3",
              title: t("START OPERATION"),
              description: t("STEP3 body"),

              // image: operate,
              // width: "600px",
            }}
            language={language} // Pass language information
          />
        </div>
      </section>
    </div>
  );
};

export default Stepper;
