import React from "react";
import Banner from "../Banner";
import Client_logo from "../Client_logo";
import AboutUs from "../AboutUs";
import ContactUs from "../ContactUs";
import Services from "../Services";
import Testimonial from "../Testimonial";
import Stepper from "../Stepper";
import Fucfact from "../Funfact";
import Whyus from "../Whyus";
import Contact_section from "../Contact_section";
import Navbar from "../Navbar";
import Footer from "../Footer";

const Home = (props) => {
  return (
    <div>
      <Navbar></Navbar>
      <Banner></Banner>
      <Client_logo></Client_logo>
      <AboutUs></AboutUs>

      <Services></Services>
      <Stepper></Stepper>
      {/* <Fucfact></Fucfact> */}
      {/* <Testimonial></Testimonial> */}
      <Whyus></Whyus>
      {/* <Contact_section></Contact_section> */}
      {/* <ContactUs></ContactUs> */}
      <Footer></Footer>
    </div>
  );
};

export default Home;
