import React, { useState } from "react";
import Navbar from "../Navbar";
import Pageheader from "../components/Pageheader";
import Footer from "../Footer";
import LanguageSwitcher from "../data/Layout";
import enTranslations from "../locales/en/english.json"; // Import English translations
import arTranslations from "../locales/ar/translation.json"; // Import Arabic translations
import { useTranslation } from "react-i18next";

const About = (props) => {
  const [language, setLanguage] = useState("ar"); // Default language is English

  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <Pageheader header={t("aboutUs")} Image="bg-hero-banner" />
      <div className="flex w-10/12 mx-auto my-10 text-white text-left -500 px-28 py-10 space-x-10 xs:w-full xs:my-3 xs:px-1 xs:py-2 xs:flex-col">
        <div className="basis-12/12 self-center xs:basis-full text-[18px] xs:px-3 xs:text-[16px]">
          {t("redExDescription")}
        </div>
      </div>
      {/* <LanguageSwitcher setLanguage={setLanguage} /> */}
      <Footer />
    </div>
  );
};

export default About;
