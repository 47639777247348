const citiesByCountry = {
  SRI: [
    "Colombo",
    "Gampaha",
    "Kalutara",
    "Kandy",
    "Matale",
    "Nuwara Eliya",
    "Galle",
    "Matara",
    "Hambantota",
    "Jaffna",
    "Kilinochchi",
    "Mannar",
    "Mullaitivu",
    "Vavuniya",
    "Puttalam",
    "Kurunegala",
    "Anuradhapura",
    "Polonnaruwa",
    "Badulla",
    "Moneragala",
    "Ratnapura",
    "Kegalle",
    "Trincomalee",
    "Batticaloa",
    "Ampara",
  ],
  KSA: [
    "Riyadh",
    "Jeddah",
    "Mecca (Makkah)",
    "Medina (Al Madinah)",
    "Dammam",
    "Ta'if",
    "Tabuk",
    "Buraydah",
    "Abha",
    "Khobar",
    "Al-Ahsa",
    "Jubail",
    "Najran",
    "Yanbu",
    "Hail",
    "Arar",
    "Al Qunfudhah",
    "Al Khafji",
    "Al Hofuf",
    "Al Kharj",
  ],
  UAE: [
    "Abu Dhabi",
    "Dubai",
    "Sharjah",
    "Ajman",
    "Ras Al Khaimah",
    "Fujairah",
    "Umm Al Quwain",
  ],
  QAT: [
    "Doha",
    "Al Wakrah",
    "Al Khor",
    "Al Rayyan",
    "Umm Salal",
    "Al Daayen",
    "Mesaieed",
  ],
  BAH: [
    "Manama",
    "Muharraq",
    "Riffa",
    "Hamad Town",
    "Isa Town",
    "Sitra",
    "Jidhafs",
  ],
  KWI: [
    "Kuwait City",
    "Hawalli",
    "Salmiya",
    "Jahra",
    "Al Farwaniyah",
    "Al Ahmadi",
    "Sabah Al Salem",
  ],
  // Add more cities for other countries as needed
};

export default citiesByCountry;
