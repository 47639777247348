import React from "react";
import Comingsoon from "../components/Comingsoon";

const Faq = (props) => {
  return (
    <div>
      <Comingsoon></Comingsoon>
    </div>
  );
};

export default Faq;
