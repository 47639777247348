import React from "react";

const Logistic = (props) => {
  return (
    <div>
      <svg
        fill="#35D399"
        height="40px"
        width="40px"
        version="1.1"
        id="Layer_1"
        viewBox="0 0 511.999 511.999"
      >
        <g>
          <g>
            <path
              d="M444.502,118.719v208.49H0v72.953h42.987c-1.472,3.994-2.277,8.308-2.277,12.807c0,20.48,16.661,37.141,37.14,37.141
			c20.479,0,37.14-16.661,37.14-37.141c0-4.499-0.805-8.813-2.277-12.807h249.492c-1.472,3.994-2.277,8.308-2.277,12.807
			c0,20.48,16.661,37.141,37.14,37.141c20.48,0,37.141-16.661,37.141-37.141c0-4.499-0.805-8.813-2.277-12.807h42.987V149.136
			h37.081v-30.417H444.502z"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon
              points="169.37,194.549 169.37,229.855 138.953,229.855 138.953,194.549 86.073,194.549 86.073,296.792 222.251,296.792 
			222.251,194.549 		"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon
              points="335.966,194.549 335.966,229.855 305.549,229.855 305.549,194.549 252.668,194.549 252.668,296.792 
			388.847,296.792 388.847,194.549 		"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon
              points="252.668,61.889 252.668,97.196 222.251,97.196 222.251,61.889 169.37,61.889 169.37,164.132 222.251,164.132 
			252.668,164.132 305.549,164.132 305.549,61.889 		"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logistic;
