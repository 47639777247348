import { React, useState, useEffect } from "react";
import Button from "./components/Button";
import logo from "./Images/redex_logo.png";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, Route, Routes } from "react-router-dom";
import AboutUs from "./AboutUs";
import App from "./App";
import Contact from "./pages/Contact";
import redexlogo from "./Images/redex_logo.png";
import LanguageSwitcher from "./data/Layout";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = (props) => {
  const { t } = useTranslation();

  const navigation = [
    { name: t("home"), to: "/", current: false },
    { name: t("aboutus"), to: "/about-us", current: false },
    { name: t("Serv"), to: "/services", current: false },
    // { name: "Faq", to: "/faq", current: false },
    // { name: "Pricing", to: "/pricing", current: false },
    { name: t("contat"), to: "/contact-us", current: false },
  ];

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <>
      <div
        className={`sticky top-0 z-50 ${
          scrolled
            ? "bg-red-700  absolute w-full "
            : " bg-yellow-50 absolute w-full "
        }`}
      >
        <div className="relative mx-auto">
          <div
            className={`absolute w-full  ${
              scrolled ? "bg-dark-blue py-3" : "bg-transparent"
            }`}
          >
            <Disclosure as="nav">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-full   lg:px-40">
                    <div className="relative flex h-16 items-center justify-between">
                      <a class="text-3xl font-bold font-heading" href="#">
                        <Link to={"/"}>
                          <div className=" shadow-emerald-500 xs:text-3xl text-5xl bg-gradient-to-r from-teal-600 to-emerald-400 text-transparent bg-clip-text">
                            <img src={redexlogo} width={120} loading="lazy" />
                          </div>
                        </Link>
                      </a>

                      <div className=" w-6/12 hidden lg:flex lg:justify-between">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.to}
                            className={classNames(
                              item.current
                                ? "hover:bg-gradient-to-b hover:from-teal-600 hover:to-emerald-400 text-white"
                                : "text-white hover:bg-gradient-to-b hover:from-emerald-400 hover:to-teal-600",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>

                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <div class="xs:hidden place-content-center xl:flex ">
                          {/* <LanguageSwitcher></LanguageSwitcher> */}

                          <Link to={"/services"}>
                            <button className=" hover:bg-gradient-to-b hover:from-emerald-400 hover:to-teal-600 text-sm inline-flex items-center place-content-center  rounded-md shadow-md shadow-green-500 bg-gradient-to-r from-teal-600 to-emerald-400 w-40 py-3 text-center  xs:text-sm font-semibold text-white   focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                              {" "}
                              RFQ
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="flex items-center sm:hidden">
                        {/* <LanguageSwitcher></LanguageSwitcher> */}
                        <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                          <span className="absolute -inset-0.5" />
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="sm:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2 xs:rounded-lg xs:bg-gradient-to-r from-green-500 to-emerald-700">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          as="a"
                          to={item.to}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "block rounded-md px-3 py-2 text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
