import React from "react";
import Comingsoon from "../components/Comingsoon";
import ContactUs from "../ContactUs";
import Navbar from "../Navbar";
import Banner from "../Banner";
import Footer from "../Footer";
import Pageheader from "../components/Pageheader";
import Contactform from "../components/Contactform";
import { useTranslation } from "react-i18next";

const Servicepage = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      {/* <Comingsoon></Comingsoon> */}
      <Navbar></Navbar>
      {/* <Banner></Banner> */}
      <Pageheader header={t("servic")} Image="bg-hero-banner"></Pageheader>
      <ContactUs></ContactUs>

      {/* <ContactUs></ContactUs> */}
      <Footer></Footer>
    </div>
  );
};

export default Servicepage;
