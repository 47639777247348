import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

const Comingsoon = (props) => {
  return (
    <div>
      <Navbar></Navbar>
      <div className="relative flex items-center justify-center object-center h-screen ">
        <div className="absolute inset-0 bg-hero-banner bg-cover bg-center h-screen  "></div>
        <div className="absolute inset-0 bg-gradient-to-t from from-dark-blue to-middark-blue opacity-75 bg-cover bg-center h-screen  "></div>
        <span className="absolute tracking-wider lg:text-8xl text-5xl text-emarld-700 font-serif font-extrabold">
          Coming
          <span className="mt-10 ml-4 px-5 text-white ">Soon</span>
        </span>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Comingsoon;
