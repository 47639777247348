import React from "react";

const Support = (props) => {
  return (
    <div>
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 24 24"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        fill="##35D399"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />

        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <g id="SVGRepo_iconCarrier">
          <defs></defs>

          <path
            class="cls-1"
            fill="#35D399"
            d="M22.5,18.77l-2.88,2.88c-2,2-8.18.46-12.95-4.32S.36,6.37,2.35,4.38L5.23,1.5l5,5L7.75,9.06c-1,1-.59,3,1.79,5.4s4.41,2.79,5.4,1.79l2.52-2.51Z"
          />

          <line class="cls-1" x1="22.5" y1="10.09" x2="22.5" y2="6.27" />
        </g>
      </svg>
    </div>
  );
};

export default Support;
