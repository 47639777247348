import React from "react";

const Analytics = (props) => {
  return (
    <div>
      <svg
        fill="#35D399"
        width="40px"
        height="40px"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M6,9,3,12,0,9v6H16V5L9,12ZM9,8,6,5,3,8,0,5V7l3,3L6,7l3,3,7-7V1Z" />
        </g>
      </svg>
    </div>
  );
};

export default Analytics;
