import pharma from "./Images/get_quoto/pharmacy.png";
import industry from "./Images/get_quoto/industry.png";
import logistic_img from "./Images/get_quoto/logistic_img.png";
import audit from "./Images/get_quoto/audit.png";
import Cross from "./Images/get_quoto/Cross.png";
import Werehouse from "./Images/get_quoto/Werehouse.png";
import Procedument from "./Images/get_quoto/Procedument.png";
import boutique from "./Images/get_quoto/boutique.png";
import body from "./Images/get_quoto/body.png";
import cafe from "./Images/get_quoto/cafe.png";
import distribution from "./Images/get_quoto/distribution.png";
import hospital from "./Images/get_quoto/hospital.png";
import house from "./Images/get_quoto/house.png";
import oil from "./Images/get_quoto/oil.png";
import retailer from "./Images/get_quoto/retailer.png";
import telecom from "./Images/get_quoto/telecom.png";
import team from "./Images/get_quoto/team.png";

import React, { useState, useEffect } from "react";
import citiesByCountry from "./data/City_list.js"; // Import the list of cities
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();

  const [selectedHostingOption, setSelectedHostingOption] = useState("");
  const [firstSelect, setFirstSelect] = useState("");
  const [secondSelect, setSecondSelect] = useState("");
  const [nestedSelect, setNestedSelect] = useState("");
  const [deepNestedSelect, setDeepNestedSelect] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [message, setMessage] = useState("");
  const [sizeMeter, setsizeMeter] = useState("");
  // const [period, setPeriod] = useState(['']);
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [pickup, setPickup] = useState("");
  const [drop, setDrop] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [timeCircle, setTimeCircle] = useState("");
  const [industryError, setIndustryError] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeCompanyl = (event) => {
    setCompany(event.target.value);
  };
  const handleChangePickup = (event) => {
    setPickup(event.target.value);
  };
  const handleChangeDrop = (event) => {
    setDrop(event.target.value);
  };
  const handleChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  // const handleChangePeriod = (event) => {
  //   const selectedValue = event.target.value;
  //   setPeriod((prevPeriod) => {
  //     // Remove the selected value from the array
  //     const updatedPeriod = prevPeriod.filter(
  //       (option) => option !== selectedValue
  //     );
  //     // Add the selected value at the beginning of the array
  //     return [selectedValue, ...updatedPeriod];
  //   });
  // };

  const handleChangePeriod = (event) => {
    const value = event.target.value;
    setTimeCircle(value);
  };

  const handleChangeNumber = (event) => {
    setsizeMeter(event.target.value);
  };

  // Define options for the first and second select boxes
  const [firstSelectOptions, setFirstSelectOptions] = useState([
    "Option 1",
    "Option 2",
    "Option 3",
  ]);

  const [secondSelectOptions, setSecondSelectOptions] = useState({
    Land: ["FTL", "LTL"],
    Sea: ["FTL", "LTL"],
    Air: ["FTL", "LTL"],
  });

  const [period, setPeriod] = useState(["Trip", "Daily", "Monthly", "Yearly"]);

  const nestedSelectOptions = {
    "Suboption A": ["0773273580", "Nested Option 2"],
    "Suboption B": ["Nested Option 3", "Nested Option 4"],
  };
  const deepNestedSelectOptions = {
    "Nested Option 1": ["Deep Nested 1", "Deep Nested 2"],
    "0773273580": ["Deep Nested 3", "Deep Nested 4"],
  };

  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [requireWarehouse, setRequireWarehouse] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Event handler for checkbox selection
  // Event handler for radio button selection
  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
    // setError(false); // Reset error message when a selection is made
    setIndustryError(false);
  };

  const handleWarehouseToggle = () => {
    setRequireWarehouse(!requireWarehouse);
  };

  const handleSecondSelectChange = (e) => {
    const value = e.target.value;
    setSecondSelect(value);
  };
  // Event handler for the first select box
  const handleFirstSelectChange = (event) => {
    const selectedValue = event.target.value;
    setFirstSelect(selectedValue);

    // Reset the second, nested, and deep nested select box values when the first select box changes
    // setFirstSelect("");
    setSecondSelect("");
    setNestedSelect("");
    setDeepNestedSelect("");
  };

  // Event handler for radio button selection
  const handleHostingOptionChange = (value) => {
    setSelectedHostingOption(value);
    setFirstSelect("");
    setMessage("");
    setIsChecked(false);
    setDrop("");
    setPickup("");
    setsizeMeter("");
    setRequireWarehouse(false);
    setTimeCircle("");
    setServiceError(false);
    // setSecondSelect("");
  };

  const resetFormFields = () => {
    setFirstName("");
    setPhone("");
    setEmail("");
    setCompany("");
    setSelectedIndustry("");
    setSelectedHostingOption("");
    setFirstSelect("");
    setSecondSelect("");
    setPickup("");
    setDrop("");
    setMessage("");
    setRequireWarehouse(false);
    setIsChecked(false);
    setsizeMeter("");
    setTimeCircle("");
    setIsSubmitting(false);
  };

  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);

  // Update first select options based on the selected hosting option
  useEffect(() => {
    if (selectedHostingOption === "Logistics") {
      setFirstSelectOptions(["Operating Leasing", "LTL", "TL"]);
      setSecondSelectOptions([]); // Reset second select options
    } else if (selectedHostingOption === "cross") {
      setFirstSelectOptions(["Land", "Sea", "Air"]);
      if (["Land", "Sea", "Air"].includes(firstSelect)) {
        setSecondSelectOptions({
          Land: ["FTL", "LTL"],
          Sea: ["FTL", "LTL"],
          Air: ["FTL", "LTL"],
        });
      } else setSecondSelectOptions([]); // Reset second select options
    } else if (selectedHostingOption === "werehouse") {
      setFirstSelectOptions(["UAE", "KSA", "QAT", "BAH", "KWI", "SRI"]);
      setPeriod(["Trip", "Daily", "Monthly", "Yearly"]);
      if (firstSelect && citiesByCountry[firstSelect]) {
        setSecondSelectOptions(citiesByCountry[firstSelect]);
      } else {
        setSecondSelectOptions([]);
      }
    } else if (selectedHostingOption === "Procedument") {
      setFirstSelectOptions([]); // Reset first select options
      setSecondSelectOptions([]); // Reset second select options
    } else if (selectedHostingOption === "audit") {
      setFirstSelectOptions([]); // Reset first select options
      setSecondSelectOptions([]); // Reset second select options
    }
  }, [selectedHostingOption, firstSelect]);

  // Rest of your code remains unchanged
  // Event handler for the form submission
  // {
  //   console.log("Name", firstName);
  //   console.log("mail", email);
  //   console.log("Phone", phone);
  //   console.log("company", company);
  //   console.log("industry", selectedIndustry);
  //   console.log("selectedHostingOption", selectedHostingOption);
  //   console.log("secound select option", secondSelectOptions);
  //   console.log("first select option", firstSelect);
  //   console.log("secound select option", secondSelect);
  //   console.log("pickup", pickup);
  //   console.log("drop", drop);
  //   console.log("message", message);
  //   console.log("warehouse required", requireWarehouse);
  //   console.log("agreement", isChecked);
  //   console.log("size", sizeMeter);
  //   console.log("peroid", timeCircle);
  // }
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!firstName || !phone || !email || !company) {
      setIsFormSubmitted(true);
      toast.error("Please Fill Your Personal Details");
      return;
    } else if (!selectedIndustry) {
      setIsFormSubmitted(true);
      toast.error("Please Select Your Industry");
      return;
    } else if (!selectedHostingOption) {
      setIsFormSubmitted(true);
      toast.error("Please Select A Services");
      return;
    } else if (!firstSelect && !message) {
      // If any of the conditions fail, show an error message and return
      console.error("Failed to submit form data");
      setIsFormSubmitted(true);
      toast.error("Fill The Service Details");
      return;
    } else if (!isChecked) {
      // If any of the conditions fail, show an error message and return
      console.error("Failed to submit form data");
      setIsFormSubmitted(true);
      toast.error("Agree the T&C");
      return;
    }

    const form = event.currentTarget;
    if (!selectedIndustry) {
      setIndustryError(true);
      // Optionally, you can focus on the radio group to guide the user
      // document.getElementById('industry-radio-group').focus();
    } else if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    } else if (!selectedHostingOption) {
      setServiceError(true);
    } else {
      // Proceed with your form submission or other actions
      form.classList.add("was-validated");
      // Add an event listener to prevent default form submission

      // Prepare the data to be sent to the backend
      const formData = {
        firstName: firstName,
        phone: phone,
        email: email,
        company: company,
        selectedIndustry: selectedIndustry,
        hostingOption: selectedHostingOption,
        firstselect: firstSelect,
        secoundselect: secondSelect,
        pickup: pickup,
        drop: drop,
        message: message,
        requireWarehouse: requireWarehouse,
        term: isChecked,
        size: sizeMeter,
        period: timeCircle,
      };
      setIsSubmitting(true);
      // Replace 'YOUR_BACKEND_API_URL' with the actual endpoint URL
      // const apiUrl = "http://localhost:5000/api/quotocreate";
      if (phone.length >= 9) {
        const apiUrl =
          "https://redexbackend.teammatrixmantra.com/api/quotocreate";

        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });

          if (
            response.ok &&
            isChecked &&
            selectedHostingOption &&
            selectedIndustry &&
            (firstSelect || message)
          ) {
            // event.preventDefault();
            // console.log("Form data sent successfully");
            setIsFormSubmitted(true);
            toast.success("Form submitted successfully!");
            setTimeout(() => {
              setIsSubmitting(false);
            }, 3000);

            // Reset the form fields
            resetFormFields();

            // You can handle success, e.g., show a success message or redirect
          } else {
            console.error("Failed to submit form data");
            setIsFormSubmitted(true);
            toast.error("Please fill all details ");

            // You can handle errors, e.g., show an error message
          }
        } catch (error) {
          console.error("Error:", error);
          // You can handle network errors or other exceptions
        }
      } else {
        toast.error("Enter valid phone number");
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className=" relative py-1  xs:py-0 shadow-[inset_0_-2px_200px_rgba(0,0,0,0.7)] ">
      <div className="absolute inset-0 bg-design-section opacity-10 bg-contain  bg-repeat-y bg-right -z-9"></div>
      <form className="relative" id="industryForm ">
        {/* ... (rest of the code) */}
        <div className=" mx-auto xs:my-5 my-5">
          <div className="w-10/12 lg:w-[63%] xs:w-full  mx-auto px-28 xs:px-2 p-8   shadow-xl   shadow-dark-blue  ">
            {/* <h2 className="text-2xl font-bold mb-4">My Form</h2> */}
            <div className="text-white text-xl pb-8 font-bold xs:mb-2 xs:mt-3 xs:text-left">
              {" "}
              {t("Personal Details")}
            </div>
            <div class="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text"
                ></label>
                <input
                  type="text"
                  id="first_name"
                  value={firstName}
                  onChange={handleChangeFirstName}
                  class="bg-transparent border border-emarld-700  text-gray-200 focus-visible:text-emarld-700 focus:shadow-lg focus:shadow-emerald-700 dark:text-white text-sm rounded-lg block w-full p-2.5 focus:text-emarld-700"
                  placeholder={t("firstname")}
                  required
                />
              </div>
              <div>
                <label
                  for="phone"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                ></label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={handleChangePhone}
                  class="bg-transparent border border-emarld-700  text-gray-200 focus-visible:text-emarld-700 focus:shadow-lg focus:shadow-emerald-700 dark:text-white text-sm rounded-lg block w-full p-2.5 focus:text-emarld-700"
                  placeholder="94-771234560"
                  pattern="[+][0-9]{12}"
                  required
                />
              </div>

              <div class="mb-6 xs:mb-1">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                ></label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleChangeEmail}
                  class="bg-transparent border border-emarld-700  text-gray-200 focus-visible:text-emarld-700 focus:shadow-lg focus:shadow-emerald-700 dark:text-white text-sm rounded-lg block w-full p-2.5 focus:text-emarld-700"
                  placeholder="Example@company.com"
                  required
                />
              </div>
              <div>
                <label
                  for="company"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                ></label>
                <input
                  type="text"
                  id="company"
                  value={company}
                  onChange={handleChangeCompanyl}
                  class="bg-transparent border border-emarld-700  text-gray-200 focus-visible:text-emarld-700 focus:shadow-lg focus:shadow-emerald-700 dark:text-white text-sm rounded-lg block w-full p-2.5 focus:text-emarld-700"
                  placeholder={t("company")}
                  required
                />
              </div>
            </div>

            <div className="text-white text-xl font-bold mb-10 mt-16 xs:mb-4 xs:text-left">
              {" "}
              {t("Select Your Industries")}
            </div>

            {/* select your industries  */}
            <ul class="grid w-full gap-6 md:grid-cols-2 lg:grid-cols-3 xs:grid-cols-2">
              <li className="">
                <input
                  type="radio"
                  id="pharma"
                  value="pharma"
                  className="hidden peer"
                  checked={selectedIndustry === "pharma"}
                  onChange={() => handleIndustryChange("pharma")}
                  required
                />

                <label
                  for="pharma"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5 xs:px-1   bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={pharma}
                      width={60}
                      className="mx-auto "
                    />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-[15px]">
                      {t("industries1")}
                    </div>
                  </div>
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  id="food"
                  value="food"
                  className="hidden peer"
                  checked={selectedIndustry === "food"}
                  onChange={() => handleIndustryChange("food")}
                  required
                />

                <label
                  for="food"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={cafe}
                      width={60}
                      className="mx-auto"
                    />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-[15px]">
                      {t("industries2")}
                    </div>
                  </div>
                </label>
              </li>

              {/* <li>
                <input
                  type="radio"
                  id="industry"
                  value="industry"
                  className="hidden peer"
                  checked={selectedIndustry === "industry"}
                  onChange={() => handleIndustryChange("industry")}
                  required
                />

                <label
                  for="industry"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img  loading="lazy" src={industry} width={60} className="mx-auto" />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-lg">
                      Industry
                    </div>
                  </div>
                </label>
              </li> */}

              <li>
                <input
                  type="radio"
                  id="textile"
                  value="textile"
                  className="hidden peer"
                  checked={selectedIndustry === "textile"}
                  onChange={() => handleIndustryChange("textile")}
                  required
                />

                <label
                  for="textile"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={boutique}
                      width={60}
                      className="mx-auto"
                    />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-[15px]">
                      {t("industries3")}
                    </div>
                  </div>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="agriculture"
                  value="agriculture"
                  className="hidden peer"
                  checked={selectedIndustry === "agriculture"}
                  onChange={() => handleIndustryChange("agriculture")}
                  required
                />

                <label
                  for="agriculture"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={house}
                      width={70}
                      className="mx-auto"
                    />
                    <div class="w-full text-sm font-semibold mt-0 xs:text-[15px]">
                      {t("industries4")}
                    </div>
                  </div>
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  id="Automotive"
                  value="Automotive"
                  className="hidden peer"
                  checked={selectedIndustry === "Automotive"}
                  onChange={() => handleIndustryChange("Automotive")}
                  required
                />

                <label
                  for="Automotive"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={body}
                      width={60}
                      className="mx-auto"
                    />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-[15px]">
                      {t("industries5")}
                    </div>
                  </div>
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  id="distrubtion"
                  value="distrubtion"
                  className="hidden peer"
                  checked={selectedIndustry === "distrubtion"}
                  onChange={() => handleIndustryChange("distrubtion")}
                  required
                />

                <label
                  for="distrubtion"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={distribution}
                      width={60}
                      className="mx-auto"
                    />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-[15px]">
                      {t("industries6")}
                    </div>
                  </div>
                </label>
              </li>

              {/* <li>
                <input
                  type="radio"
                  id="health"
                  value="health"
                  className="hidden peer"
                  checked={selectedIndustry === "health"}
                  onChange={() => handleIndustryChange("health")}
                  required
                />

                <label
                  for="health"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img  loading="lazy" src={hospital} width={60} />
                    <div class="w-full">Health Care</div>
                  </div>
                </label>
              </li> */}

              <li>
                <input
                  type="radio"
                  id="oil"
                  value="oil"
                  className="hidden peer"
                  checked={selectedIndustry === "oil"}
                  onChange={() => handleIndustryChange("oil")}
                  required
                />

                <label
                  for="oil"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={oil}
                      width={60}
                      className="mx-auto"
                    />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-[15px]">
                      {t("industries7")}
                    </div>
                  </div>
                </label>
              </li>

              {/* <li>
                <input
                  type="radio"
                  id="retails"
                  value="retails"
                  className="hidden peer"
                  checked={selectedIndustry === "retails"}
                  onChange={() => handleIndustryChange("retails")}
                  required
                />

                <label
                  for="retails"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img  loading="lazy" src={retailer} width={60} className="mx-auto" />
                    <div class="w-full">Retails</div>
                  </div>
                </label>
              </li> */}

              <li>
                <input
                  type="radio"
                  id="telecom"
                  value="telecom"
                  className="hidden peer"
                  checked={selectedIndustry === "telecom"}
                  onChange={() => handleIndustryChange("telecom")}
                  required
                />

                <label
                  for="telecom"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={telecom}
                      width={70}
                      className="mx-auto"
                    />
                    <div class="w-full text-sm font-semibold mt-0 xs:text-[15px]">
                      {t("industries8")}
                    </div>
                  </div>
                </label>
              </li>

              {/* <li>
                <input
                  type="radio"
                  id="food"
                  value="food"
                  className="hidden peer"
                  checked={selectedIndustry === "food"}
                  onChange={() => handleIndustryChange("food")}
                  required
                />

                <label
                  for="food"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img  loading="lazy" src={cafe} width={60} className="mx-auto" />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-lg">
                      Food & Beverages
                    </div>
                  </div>
                </label>
              </li> */}

              <li>
                <input
                  type="radio"
                  id="industry"
                  value="industry"
                  className="hidden peer"
                  checked={selectedIndustry === "industry"}
                  onChange={() => handleIndustryChange("industry")}
                  required
                />

                <label
                  for="industry"
                  class="inline-flex items-center   justify-between mx-auto text-white w-full p-5  bg-transparent border border-emerald-500 rounded-lg cursor-pointer  dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-emerald-700 peer-checked:text-emerald-500 filter brightness-75 hover:brightness-110 peer-checked:brightness-125 peer-checked:shadow-md peer-checked:shadow-emerald-500 hover:text-gray-600 hover:shadow-md hover:shadow-green-500  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block mx-auto text-center justify-center">
                    <img
                      loading="lazy"
                      src={industry}
                      width={60}
                      className="mx-auto"
                    />
                    <div class="w-full text-sm font-semibold mt-2 xs:text-[15px]">
                      {t("industries9")}
                    </div>
                  </div>
                </label>
              </li>
            </ul>
            {industryError && (
              <p className="text-red-500">Please select an industry.</p>
            )}
            {selectedIndustry && (
              <div className="text-white text-xl font-bold mb-10 mt-16">
                {" "}
                {t("Choose Your Service")}
              </div>
            )}
            {/* Radio Buttons for Services Options */}
            {selectedIndustry && (
              <div className="mt-10">
                <ul className="grid w-full  gap-6 md:grid-cols-2 lg:grid-cols-3 xs:grid-cols-2">
                  <li>
                    <input
                      type="radio"
                      id="Logistics"
                      name="Logistics"
                      value="Logistics"
                      className="hidden peer"
                      checked={selectedHostingOption === "Logistics"}
                      onChange={() => handleHostingOptionChange("Logistics")}
                    />
                    {/* ... (rest of the radio button code) */}
                    <label
                      htmlFor="Logistics"
                      class="inline-flex filter brightness-75 hover:brightness-100 peer-checked:brightness-110 hover:shadow-lg hover:shadow-emarld-700 peer-checked:shadow-lg peer-checked:shadow-emarld-700 items-center justify-between w-full p-5 xs:p-2 text-gray-300  bg-transparent border border-emarld-700 rounded-lg cursor-pointer   dark:peer-checked:text-emerald-500 peer-checked:border-emarld-700 peer-checked:text-emarld-700 hover:text-gray-600"
                    >
                      <div class="block  mx-auto xs:py-3 ">
                        <img
                          loading="lazy"
                          src={logistic_img}
                          width={80}
                          className="mx-auto xs:w-30 "
                        />
                        <div class="w-full text-sm xs:-mt-2 xs:text-[15px] lg:-mt-[10px]  font-semibold">
                          {t("indu_service1")}
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="cross"
                      name="cross"
                      value="cross"
                      className="hidden peer"
                      checked={selectedHostingOption === "cross"}
                      onChange={() => handleHostingOptionChange("cross")}
                    />
                    {/* ... (rest of the radio button code) */}
                    <label
                      htmlFor="cross"
                      class="inline-flex filter brightness-75 hover:brightness-100 peer-checked:brightness-110 hover:shadow-lg hover:shadow-emarld-700 peer-checked:shadow-lg peer-checked:shadow-emarld-700 items-center justify-between w-full p-5 xs:p-2 text-gray-300  bg-transparent border border-emarld-700 rounded-lg cursor-pointer   dark:peer-checked:text-emerald-500 peer-checked:border-emarld-700 peer-checked:text-emarld-700 hover:text-gray-600"
                    >
                      <div class="block  mx-auto xs:py-3">
                        <img
                          loading="lazy"
                          src={Cross}
                          width={70}
                          className="mx-auto xs:w-13"
                        />
                        <div class="w-full text-sm xs:text-[15px]  font-semibold">
                          {t("indu_service2")}
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="werehouse"
                      name="werehouse"
                      value="werehouse"
                      className="hidden peer"
                      checked={selectedHostingOption === "werehouse"}
                      onChange={() => handleHostingOptionChange("werehouse")}
                    />
                    {/* ... (rest of the radio button code) */}
                    <label
                      htmlFor="werehouse"
                      class="inline-flex filter brightness-75 hover:brightness-100 peer-checked:brightness-110 hover:shadow-lg hover:shadow-emarld-700 peer-checked:shadow-lg peer-checked:shadow-emarld-700 items-center justify-between w-full p-5 xs:p-2 text-gray-300  bg-transparent border border-emarld-700 rounded-lg cursor-pointer   dark:peer-checked:text-emerald-500 peer-checked:border-emarld-700 peer-checked:text-emarld-700 hover:text-gray-600"
                    >
                      <div class="block  mx-auto xs:py-3">
                        <img
                          loading="lazy"
                          src={Werehouse}
                          width={70}
                          className="mx-auto xs:w-13"
                        />
                        <div class="w-full text-sm xs:text-[15px]  font-semibold">
                          {t("indu_service3")}
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="Procedument"
                      name="Procedument"
                      value="Procedument"
                      className="hidden peer"
                      checked={selectedHostingOption === "Procedument"}
                      onChange={() => handleHostingOptionChange("Procedument")}
                    />
                    {/* ... (rest of the radio button code) */}
                    <label
                      htmlFor="Procedument"
                      class="inline-flex filter brightness-75 hover:brightness-100 peer-checked:brightness-110 hover:shadow-lg hover:shadow-emarld-700 peer-checked:shadow-lg peer-checked:shadow-emarld-700 items-center justify-between w-full p-5 xs:p-2 text-gray-300  bg-transparent border border-emarld-700 rounded-lg cursor-pointer   dark:peer-checked:text-emerald-500 peer-checked:border-emarld-700 peer-checked:text-emarld-700 hover:text-gray-600"
                    >
                      <div class="block  mx-auto xs:py-3">
                        <img
                          loading="lazy"
                          src={Procedument}
                          width={70}
                          className="mx-auto xs:w-13"
                        />
                        <div class="w-full text-sm xs:text-[15px]  font-semibold">
                          {t("indu_service4")}
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="audit"
                      name="audit"
                      value="audit"
                      className="hidden peer"
                      checked={selectedHostingOption === "audit"}
                      onChange={() => handleHostingOptionChange("audit")}
                    />
                    {/* ... (rest of the radio button code) */}
                    <label
                      htmlFor="audit"
                      class="inline-flex filter brightness-75 hover:brightness-100 peer-checked:brightness-110 hover:shadow-lg hover:shadow-emarld-700 peer-checked:shadow-lg peer-checked:shadow-emarld-700 items-center justify-between w-full p-5 xs:p-2 text-gray-300  bg-transparent border border-emarld-700 rounded-lg cursor-pointer   dark:peer-checked:text-emerald-500 peer-checked:border-emarld-700 peer-checked:text-emarld-700 hover:text-gray-600"
                    >
                      <div class="block  mx-auto xs:py-3">
                        <img
                          loading="lazy"
                          src={audit}
                          width={70}
                          className="mx-auto xs:w-13"
                        />
                        <div class="w-full text-sm xs:text-[15px]  font-semibold">
                          {t("indu_service5")}
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="team"
                      name="team"
                      value="team"
                      className="hidden peer"
                      checked={selectedHostingOption === "team"}
                      onChange={() => handleHostingOptionChange("team")}
                    />
                    {/* ... (rest of the radio button code) */}
                    <label
                      htmlFor="team"
                      class="inline-flex filter brightness-75 hover:brightness-100 peer-checked:brightness-110 hover:shadow-lg hover:shadow-emarld-700 peer-checked:shadow-lg peer-checked:shadow-emarld-700 items-center justify-between w-full p-5 xs:p-2 text-gray-300  bg-transparent border border-emarld-700 rounded-lg cursor-pointer   dark:peer-checked:text-emerald-500 peer-checked:border-emarld-700 peer-checked:text-emarld-700 hover:text-gray-600"
                    >
                      <div class="block  mx-auto xs:py-3">
                        <img
                          loading="lazy"
                          src={team}
                          width={70}
                          className="mx-auto xs:w-13"
                        />
                        <div class="w-full text-sm xs:text-[15px]  font-semibold">
                          {t("indu_service6")}
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
                {/* {serviceError && (
                <p className="text-red-500">Please select an service.</p>
              )} */}
              </div>
            )}

            <div className="mt-16 flex flex-col ">
              {/* ... (rest of the code) */}
              {(selectedHostingOption === "Logistics" ||
                selectedHostingOption === "cross" ||
                selectedHostingOption === "werehouse") && (
                <div className="mb-4 text-left">
                  <label
                    htmlFor="firstSelect"
                    className="block text-sm font-medium text-gray-600"
                  ></label>
                  <select
                    id="firstSelect"
                    name="firstSelect"
                    value={firstSelect}
                    onChange={handleFirstSelectChange}
                    className="w-auto mt-1 mb-5 p-2 border rounded-md  xs:w-full justify-start center  bg-transparent text-emarld-700 outline-none ring ring-transparent "
                  >
                    {selectedHostingOption === "werehouse" && (
                      <option value="">{t("Select a Country")}</option>
                    )}
                    {(selectedHostingOption === "Logistics" ||
                      selectedHostingOption === "cross") && (
                      <option value="">{t("Select a options")}</option>
                    )}

                    {firstSelectOptions.map((option) => (
                      <option
                        className="text-black"
                        key={option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                  {/* Error message for First Select */}
                  {/* {!firstSelect && selectedHostingOption && (
                  <p className="text-red-500">
                    Please select an option for First Select.
                  </p>
                )} */}

                  {/* {!firstSelect && selectedHostingOption !== "werehouse" && (
                  <p className="text-red-500">
                    Please select an option for First Select.
                  </p>
                )} */}

                  <div className="mb-4">
                    <label
                      htmlFor="firstSelect"
                      className="block text-sm font-medium text-gray-600"
                    ></label>
                    {secondSelectOptions.length > 0 &&
                      citiesByCountry[firstSelect] && (
                        <select
                          value={secondSelect}
                          onChange={handleSecondSelectChange}
                          className="mt-1 mb-2 p-2 border rounded-md w-full  bg-transparent text-emarld-700"
                        >
                          <option value="">{t("Select a city")}</option>
                          {secondSelectOptions.map((city) => (
                            <option
                              className="text-black"
                              key={city}
                              value={city}
                            >
                              {city}
                            </option>
                          ))}
                        </select>
                      )}
                  </div>
                  {/* Error message for Second Select */}
                  {/* {!secondSelect &&
                  secondSelectOptions.length > 0 &&
                  citiesByCountry[firstSelect] && (
                    <p className="text-red-500">Please select a city.</p>
                  )} */}
                  <div>
                    {citiesByCountry[firstSelect] && (
                      <div>
                        <label
                          for="number-input"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        ></label>
                        <input
                          type="number"
                          id="number-input"
                          aria-describedby="helper-text-explanation"
                          class=" bg-transparent mb-5 text-emarld-700 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder={t("size")}
                          value={sizeMeter}
                          onChange={handleChangeNumber}
                          required
                        />
                        {/* Error message for Size Input */}
                        {/* {!sizeMeter && citiesByCountry[firstSelect] && (
                        <p className="text-red-500">
                          Please Enter Size in Square Meter
                        </p>
                      )} */}

                        <label
                          htmlFor="periodd"
                          className="block text-sm font-medium text-gray-600"
                        ></label>

                        <select
                          value={timeCircle}
                          onChange={handleChangePeriod}
                          className="mt-1 mb-5 p-2 border rounded-md w-full  bg-transparent text-emarld-700"
                        >
                          <option value="">{t("Select Period")}</option>
                          {period.map((periodOption) => (
                            <option key={periodOption} value={periodOption}>
                              {periodOption}
                            </option>
                          ))}
                        </select>

                        {/* Error message for Size Input */}
                        {/* {!timeCircle && citiesByCountry[firstSelect] && (
                        <p className="text-red-500">Please Select Peroid</p>
                      )} */}

                        <label
                          for="message"
                          class="block mb-2 text-sm font-medium  dark:text-white"
                        ></label>
                        <textarea
                          id="message"
                          rows="4"
                          class="block mb-5 p-2.5 w-full text-sm  bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  bg-transparent text-emarld-700"
                          placeholder={t("messagebox")}
                          value={message}
                          onChange={handleChange}
                        ></textarea>
                        {/* Error message for Size Input */}
                        {/* {!message && citiesByCountry[firstSelect] && (
                        <p className="text-red-500">
                          Please Describe somting extra.
                        </p>
                      )} */}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Second Select Box */}
              {/* Second Select Box - Conditionally rendered */}
              {["Land", "Sea", "Air"].includes(firstSelect) && (
                <div>
                  <label
                    htmlFor="secondSelect"
                    className="block text-sm font-medium text-gray-600"
                  ></label>
                  <select
                    id="secondSelect"
                    name="secondSelect"
                    value={secondSelect}
                    onChange={(e) => setSecondSelect(e.target.value)}
                    className=" mb-6 p-2 border rounded-md w-full  bg-transparent text-emarld-700"
                  >
                    <option value="">{t("Select a options")}</option>
                    {secondSelectOptions[firstSelect]?.map((suboption) => (
                      <option
                        className="text-black"
                        key={suboption}
                        value={suboption}
                      >
                        {suboption}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {(selectedHostingOption === "Procedument" ||
                selectedHostingOption === "audit" ||
                selectedHostingOption === "team") && (
                <div>
                  {/* <label
                    for="message"
                    class="block mb-2 text-sm font-medium text-white dark:text-white"
                  >
                    Your message
                  </label> */}
                  <textarea
                    id="message"
                    rows="4"
                    class="block  mb-10 p-2.5 w-full text-sm text-emarld-700  bg-transparent rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={t("messagebox")}
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  {/* {!message && selectedHostingOption && (
                  <p className="text-red-500">Please Discribe Extra details</p>
                )} */}
                </div>
              )}

              {(firstSelect === "Land" ||
                firstSelect === "Sea" ||
                firstSelect === "Air") && (
                <div>
                  <div class="grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-2">
                    <div>
                      <label
                        htmlFor="nestedSelect"
                        className="block text-sm font-medium text-gray-600"
                      ></label>
                      <input
                        type="text"
                        id="pickup"
                        value={pickup}
                        onChange={handleChangePickup}
                        class="bg-transparent   border border-gray-300 text-emerald-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder={t("pick")}
                        required
                      />
                      {!pickup && firstSelect && (
                        <p className="text-red-500 text-[12px]">
                          {t("pick_error")}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="nestedSelect"
                        className="block text-sm font-medium text-gray-600"
                      ></label>
                      <input
                        type="text"
                        id="dropof_location"
                        value={drop}
                        onChange={handleChangeDrop}
                        class="bg-transparent border border-gray-300 text-emarld-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder={t("drop")}
                        required
                      />
                      {!drop && firstSelect && (
                        <p className="text-red-500 text-[12px]">
                          {t("drop_error")}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    {/* <label
                      for="message"
                      class="block mb-2 text-sm font-medium text-white dark:text-white"
                    >
                      Your message
                    </label> */}
                    <textarea
                      id="message"
                      rows="4"
                      value={message}
                      onChange={handleChange}
                      class="block mb-7 p-2.5 w-full text-sm text-emarld-700 bg-transparent rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder={t("messagebox")}
                    ></textarea>
                    {/* {!message && firstSelect && (
                    <p className="text-red-500">
                      Please Describe Extra Details
                    </p>
                  )} */}
                  </div>

                  <div className="mb-4 w-full">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="warehouseToggle"
                        checked={requireWarehouse}
                        onChange={handleWarehouseToggle}
                        className="w-5 h-5 mr-1 text-emarld-700 peer-checked:bg-emarld-700 focus:ring-green-500 border-gray-300 rounded"
                      />

                      <label
                        htmlFor="warehouseToggle"
                        className="block text-left text-sm font-medium text-emarld-700"
                      >
                        {t("require")}
                      </label>
                    </div>
                  </div>
                </div>
              )}

              <div class="flex items-start mb-6 mt-10 xs:justify-center">
                <div class="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    checked={isChecked}
                    onChange={handleChangeCheckbox}
                    class=" w-5 h-5 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    required
                  />
                </div>
                <label
                  for="remember"
                  class="ms-2 text-sm font-medium text-white dark:text-gray-300 xs:justify-center"
                >
                  {t("agree1")}{" "}
                  <a href="#" class="text-emarld-700 hover:underline ">
                    {t("agree2")}
                  </a>
                  .
                </label>
              </div>
              {/* Submit Button */}
              <div className="mb-4">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting} // Disable button if submitting
                  // disabled={serviceError || industryError} // Adjust 'otherErrors' according to your state management
                  className="disabled:brightness-50 filter  text-[16px] inline-flex xs:px-12 xs:py-4 items-center  rounded-md shadow-md shadow-green-500 bg-gradient-to-r from-teal-600 to-emerald-400 px-8 py-3 xs:text-sm font-semibold text-white  hover:bg-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {isSubmitting ? t("senting") : t("sent")}
                </button>
                {(!selectedIndustry || serviceError || !firstSelect) && (
                  <p className="text-red-600 mt-6 text-[12px]  ">
                    {t("waring")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
